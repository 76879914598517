import { Suspense, lazy, useEffect, useState, createContext } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector, useStore } from "react-redux";
import dayjs from "dayjs";
import { addYears } from "date-fns";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";

import LoadingScreen from "components/LoadingScreen";
import useStorage from "hooks/storage";
import useRequestHandler from "services/RequestHandler";
import useCookie from "hooks/cookie";
import NotificationManager from "components/NotificationManager";
import { getPublicNotifications } from "services/publicAPI";
import useNotification from "hooks/notification";
import { setupAxiosInterceptors } from "services/clients";

import "./App.css";
import "dayjs/locale/ro";
import { selectSubscriptionStatus } from "reducers/subscriptionStatusSlice";
import { setDeviceSelection } from "reducers/deviceSlice";

export const InstalableContext = createContext();

const NoPage = lazy(() => import("./pages/Error404"));
const DownPage = lazy(() => import("./pages/Error500"));

const Layout = lazy(() => import("./pages/authorized/Layout"));
const HomePage = lazy(() => import("./pages/authorized/Home"));
const DisabledAccountPage = lazy(() =>
  import("./pages/public/DisabledAccount")
);
const AccountPage = lazy(() => import("./pages/authorized/Account"));
const InvoicesSendListPage = lazy(() =>
  import("./pages/authorized/InvoicesSendList")
);

const InvoicesReceiveListPage = lazy(() =>
  import("./pages/authorized/InvoicesReceiveList")
);

const ETVA = lazy(() => import("./pages/authorized/eTVA/ETVA"));

const InvoiceSendPage = lazy(() =>
  import("./pages/authorized/InvoiceSendBulk/InvoicesSend")
);

const RegisterSuccess = lazy(() =>
  import("./pages/public/Register/RegisterSuccess")
);
const RegisterFail = lazy(() => import("./pages/public/Register/RegisterFail"));

const InvoicesNewSimplePage = lazy(() =>
  import("./pages/authorized/CreateNewInvoice")
);
const InvoiceDraftsPage = lazy(() =>
  import("./pages/authorized/InvoiceDrafts")
);

const RecurrentInvoicePage = lazy(() =>
  import("./pages/authorized/RecurentInvoice/RecurrentInvoices")
);

const RecurrentAfterSendPage = lazy(() =>
  import("./pages/authorized/RecurentInvoice/ResultAfterSendRecurent.js")
);

const RecurrentInvoiceEditor = lazy(() =>
  import("./pages/authorized/RecurentInvoice/RecurrentInvoiceEditor.js")
);
const RecurrentProductsEditor = lazy(() =>
  import("./pages/authorized/RecurentInvoice/RecurrentProductsEditor.js")
);
const CreateNewRecurrentInvoice = lazy(() =>
  import("./pages/authorized/RecurentInvoice/CreateRecurrentInvoice.js")
);
const AccountPageCompanies = lazy(() =>
  import("./pages/authorized/Company/AccountPage")
);

const ClientNotificationsPage = lazy(() =>
  import("./pages/authorized/Notification/ClientNotifications")
);

const SubscribePage = lazy(() =>
  import("./pages/authorized/PaymentSectionUser/SubscriptionPlanSelection")
);
const PageAPI = lazy(() => import("./pages/authorized/PageAPI"));
const ResultAfterPayPage = lazy(() =>
  import("./pages/authorized/PaymentSectionUser/ResultAfterPay")
);
const ResultBackUpConnection = lazy(() =>
  import("./pages/authorized/Backup/BackUpConnectionResult")
);
const ResultPayOpPage = lazy(() =>
  import("./pages/authorized/PaymentSectionUser/ResultPayOp")
);

const VATRates = lazy(() => import("./pages/authorized/CoteTVA/VATRates"));

const AuthLayout = lazy(() => import("./pages/public/AuthLayout"));
const LoginPage = lazy(() => import("./pages/public/Login"));
const RegisterPage = lazy(() => import("./pages/public/Register"));

const ForgotPasswordSendEmailPage = lazy(() =>
  import("./pages/public/ForgotPasswordSendEmail")
);

const ConfirmPage = lazy(() => import("./pages/public/Confirm"));

const Callback = lazy(() => import("./pages/public/Callback"));

const RegisterCompanyPage = lazy(() =>
  import("./pages/public/Register/RegisterCompany")
);
const RegisterDetailsCompanyPage = lazy(() =>
  import("./pages/public/Register/RegisterDetailsCompany")
);

const GeneralDetailsSectionPage = lazy(() =>
  import("./pages/authorized/GeneralDetailsSection")
);
const SerialRegisterPage = lazy(() =>
  import("./components/CompanyDefinitions/Serials/SerialRegister")
);
const InvoicePersonalizationPage = lazy(() =>
  import("./pages/authorized/InvoicePersonalization")
);

const PartnerDefinitionPage = lazy(() =>
  import("./components/CompanyDefinitions/Partner/PartnerDefinition")
);
const SerialDefinitionPage = lazy(() =>
  import("./components/CompanyDefinitions/Serials/SerialDefinition")
);
const ProductDefinitionPage = lazy(() =>
  import("./components/CompanyDefinitions/Products/ProductCompany")
);
const PaymentConfiguration = lazy(() =>
  import("./pages/authorized/PaymentConfiguration/PaymentConfiguration")
);

const ForgotPasswordResetPage = lazy(() =>
  import("./pages/public/ForgotResetPassword")
);
const ContactUsPage = lazy(() => import("./pages/authorized/ContactUs"));

const ConvertXmlToPdfPage = lazy(() =>
  import("./pages/authorized/XmlToPDF/ConvertXmlToPdf.js")
);

const GoodByeRemoveAccountPage = lazy(() =>
  import("./pages/authorized/Account/GoodByeRemoveAccount")
);
const NewTransportHomePage = lazy(() =>
  import("./pages/eTransport/NewTransportHome")
);
const ViewETransportHomePage = lazy(() =>
  import("./pages/eTransport/ViewEtransport")
);

const EtransTransportatorsPage = lazy(() =>
  import("./pages/eTransport/Transporters/Transporters")
);
const PreviewValidationComponentPage = lazy(() =>
  import("./pages/authorized/RecurentInvoice/PreviewValidationComponent")
);

const InvoiceFileExportPage = lazy(() =>
  import("./pages/authorized/Export/InvoiceFileExport")
);
const AuditUserLogsPage = lazy(() =>
  import("./pages/authorized/AllLogs/AuditLogs")
);
const InvoiceMessages = lazy(() =>
  import("./components/InvoiceTable/InvoiceMessages.js")
);
setupAxiosInterceptors();

const App = () => {
  const store = useStore();
  const storage = useStorage();
  const { notify: notification } = useNotification();
  const subscriptionStatus = useSelector(selectSubscriptionStatus);
  const dispatch = useDispatch();

  const notify = (message, id, type) => {
    notification({
      type,
      title: "Notificare de interes public",
      message,
      id,
      delay: 120,
    });
  };

  const { getCookie, setCookie } = useCookie("_es_ip");
  const [defPrompt, setPrompt] = useState(null);
  const [instalable, setInstalable] = useState(false);

  const handleSet = (value) => {
    const expDate = addYears(new Date(), 1);
    setCookie(value, { expires: expDate });
  };

  // Funcția de detectare a tipului de dispozitiv
  const detectdeviceIsSmartphone = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isMobileDevice =
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);

    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (isMobileDevice || screenWidth <= 768) {
      return dispatch(setDeviceSelection(true));
    }

    return dispatch(setDeviceSelection(false));
  };

  useEffect(() => {
    detectdeviceIsSmartphone();
  }, []);

  const handleInstall = () => {
    if (!defPrompt) return;

    defPrompt.prompt();
    defPrompt.userChoice.then((res) => {
      setPrompt(null);
      handleSet(false);
      setInstalable(false);
    });
  };

  const handleRefuse = () => {
    handleSet(false);
  };

  useRequestHandler();

  useEffect(() => {
    dayjs.locale("ro");
  }, []);

  useEffect(() => {
    getPublicNotifications()
      .then((res) => {
        res.data.forEach((notification) => {
          console.log("notification is", notification.mesaj);
          notify(notification.mesaj, `user-${notification.id}`, "info");
        });
      })
      .catch((err) => {
        console.error("Failed to retrieve user notifications");
      });
  }, []);

  useEffect(() => {
    // console.debug("Subscribed app");

    let unsubscribe = store.subscribe(() => {
      //TODO: replace storage with indexeddb
      storage.put("redux", JSON.stringify(store.getState()));
    });

    return () => {
      // console.debug("Unsubscribed app");
      unsubscribe();
    };
  }, [store, storage]);

  useEffect(() => {
    const canInstall = getCookie();
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setInstalable(false);
      setCookie(false);
      return;
    }

    if (canInstall == undefined) setInstalable(true);

    const ready = (e) => {
      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <InstalableContext.Provider
        value={{
          canInstall: instalable,
          onAccept: handleInstall,
          onRefuse: handleRefuse,
        }}
      >
        <NotificationManager />
        <HelmetProvider>
          <Helmet>
            <title>eFactura StillCo</title>
          </Helmet>

          <BrowserRouter>
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                {/* private routes, need auth */}
                <Route path="app" element={<Layout />}>
                  <Route index element={<HomePage />} />
                  <Route path="account" element={<AccountPage />} />
                  <Route path="invoices">
                    <Route path="out" element={<InvoicesSendListPage />} />
                    <Route path="in" element={<InvoicesReceiveListPage />} />
                    <Route path="messages" element={<InvoiceMessages />} />
                    <Route path="export" element={<InvoiceFileExportPage />} />
                    <Route path="etva" element={<ETVA />} />
                    <Route path="editor" element={<InvoicesNewSimplePage />} />
                    <Route path="drafts" element={<InvoiceDraftsPage />} />
                    <Route
                      path="recurrent"
                      element={<RecurrentInvoicePage />}
                    />
                    <Route
                      path="recaftersend"
                      element={<RecurrentAfterSendPage />}
                    />
                    <Route
                      path="recurrentEditor"
                      element={<RecurrentInvoiceEditor />}
                    />
                    <Route
                      path="recurrentProductsEditor"
                      element={<RecurrentProductsEditor />}
                    />
                    <Route
                      path="createRecurrent"
                      element={<CreateNewRecurrentInvoice />}
                    />
                    <Route path="send" element={<InvoiceSendPage />} />
                    <Route path="view" element={<ConvertXmlToPdfPage />} />
                    <Route
                      path="preview"
                      element={<PreviewValidationComponentPage />}
                    />
                    <Route index element={<InvoicesNewSimplePage />} />
                  </Route>
                  <Route path="defs">
                    {/* <Route path="partner" element={<PartnerDefinitionPage />} /> */}
                    <Route
                      path="partner"
                      element={<PartnerDefinitionPage external={false} />}
                    />
                    <Route path="product" element={<ProductDefinitionPage />} />
                    <Route path="payment" element={<PaymentConfiguration />} />
                    <Route path="vat" element={<VATRates />} />
                    <Route path="serial" element={<SerialDefinitionPage />} />
                  </Route>
                  <Route path="logs">
                    <Route path="userLogs" element={<AuditUserLogsPage />} />
                  </Route>
                  <Route path="eTrans">
                    <Route
                      path="viewEtrans"
                      element={<ViewETransportHomePage />}
                    />
                    <Route
                      path="eTransHome"
                      element={<NewTransportHomePage />}
                    />
                    <Route path="defs">
                      <Route
                        path="eTransPartner"
                        element={<PartnerDefinitionPage external={true} />}
                      />
                      <Route
                        path="transportators"
                        element={<EtransTransportatorsPage />}
                      />
                    </Route>
                  </Route>

                  <Route path="config">
                    <Route
                      path="clientnotifications"
                      element={<ClientNotificationsPage />}
                    />
                  </Route>
                  <Route path="load" element={<LoadingScreen />} />
                  <Route path="500" element={<DownPage />} />
                  <Route path="*" element={<NoPage />} />
                  <Route path="config">
                    <Route
                      path="generalDetails"
                      element={<GeneralDetailsSectionPage />}
                    />
                    <Route
                      path="serialRegister"
                      element={<SerialRegisterPage />}
                    />
                    <Route
                      path="InvPersonaliz"
                      element={<InvoicePersonalizationPage />}
                    />
                  </Route>
                  <Route path="companies" element={<AccountPageCompanies />} />

                  <Route
                    path="backuprescon"
                    element={<ResultBackUpConnection service="google" />}
                  />
                  <Route path="backup">
                    {/* <Route path="google" element={<ResultBackUpGDConnection />} /> */}
                    <Route
                      path="onedrive"
                      element={<ResultBackUpConnection service="onedrive" />}
                    />
                  </Route>

                  <Route path="subscribe">
                    <Route path="payResult" element={<ResultAfterPayPage />} />
                    <Route path="payResultOp" element={<ResultPayOpPage />} />
                    <Route index element={<SubscribePage />} />
                  </Route>
                  <Route path="API" element={<PageAPI />} />
                  <Route path="contact" element={<ContactUsPage />} />
                </Route>
                {/* public routes, don't need auth */}
                <Route element={<AuthLayout />}>
                  <Route index element={<LoginPage />} />
                  <Route path="500" element={<DownPage />} />
                  <Route path="*" element={<NoPage />} />
                  <Route path="regComp" element={<RegisterCompanyPage />} />
                  <Route
                    path="regDetailComp"
                    element={<RegisterDetailsCompanyPage />}
                  />

                  <Route path="register" element={<RegisterPage />} />
                  <Route path="registerSuccess" element={<RegisterSuccess />} />
                  <Route path="disabled" element={<DisabledAccountPage />} />
                  <Route path="registerFail" element={<RegisterFail />} />
                  <Route path="confirm" element={<ConfirmPage />} />
                  <Route path="callback" element={<Callback />} />
                  <Route
                    path="password"
                    element={<ForgotPasswordSendEmailPage />}
                  />
                  <Route path="reset" element={<ForgotPasswordResetPage />} />
                  <Route
                    path="goodbye"
                    element={<GoodByeRemoveAccountPage />}
                  />
                  <Route
                    path="InvPersonaliz"
                    element={<InvoicePersonalizationPage />}
                  />
                </Route>
                {/* <Route path="subscribe">
                  <Route path="payResult" element={<ResultAfterPayPage />} />
                  <Route path="payResultOp" element={<ResultPayOpPage />} />
                  <Route index element={<SubscribePage />} />
                </Route> */}
                <Route index element={<Navigate to="/auth" />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </HelmetProvider>
      </InstalableContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
